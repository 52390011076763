<template>
  <div class="alert-container">
    <b-alert
      v-for="(message, index) in messages"
      :key="index"
      show
      fade
      dismissible
      :variant="message.type"
    >
      <span>{{ message.text }}</span>
    </b-alert>
  </div>
</template>

<script>
import {
  sucessMessageListener,
  errorMessageListener,
  infoMessageListener,
  messageBus,
} from "./MessageBus";

export default {
  name: "MessageScreen",
  data() {
    return {
      messages: [],
      financial: {},
      timeout: 3500,
    };
  },
  methods: {
    addErrorMessage(text) {
      this.messages.push({
        type: "danger",
        text,
      });
      this.removeAlert();
    },
    addSuccessMessage(text) {
      this.messages.push({
        type: "success",
        text,
      });
      this.removeAlert();
    },
    addInfoMessage(text) {
      this.messages.push({
        type: "warning",
        text,
      });
      this.removeAlert();
    },
    removeAlert() {
      setTimeout(() => {
        this.messages.shift();
      }, this.timeout);
    },
  },
  mounted() {
    messageBus.$on(sucessMessageListener, (message) => {
      this.addSuccessMessage(message);
    });
    messageBus.$on(errorMessageListener, (message) => {
      this.addErrorMessage(message);
    });
    messageBus.$on(infoMessageListener, (message) => {
      this.addInfoMessage(message);
    });
  },
};
</script>

<style>
.alert-container {
  position: fixed;
  top: 5px;
  z-index: 10002;
  width: 25%;
  right: 15px;
}
</style>
