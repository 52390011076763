<template>
  <div class="alert-container-payment justify-center d-flex" v-if="isFinantialPending">
    <b-alert show variant="danger" dismissible>
      <div class="d-flex justify-content-start">
        <div>
          <b-icon
            icon="exclamation-triangle-fill"
            title="Alerta pagamento"
          ></b-icon>
        </div>
        <div><h4 class="alert-heading payment-text ml-2">Pagamento</h4></div>
      </div>

      <p v-html="paymentMessage"></p>
    </b-alert>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "MessagePayment",
  data() {
    return {
      paymentMessage: `Não foi identificado o pagamento de sua fatura, evite o bloqueio do seu serviço.
      Para pegar a 2º via do seu boleto utilize uma das seguintes formas:
      <ul style="padding-top: 10px">
        <li>
            Nova tela de pagamentos
            <a
              href="/#/payments"
              class="w-100"
            >
              <img class="img-icon"/>
              <span class="whatsapp">Clicando aqui</span>
            </a>
        </li>
        <li>Entrando em contato com o
        suporte via whatsapp
          <p>
            <a
              href="https://wa.me/+553140424391?text=Olá preciso de ajuda com o pagamento"
              class="w-100"
              target="_blank"
            >
              <img class="img-icon"/>
              <span class="whatsapp">(31) 4042-4391</span>
            </a>
          </p>
        </li>
      </ul>`,
    };
  },
  computed: {
    ...mapGetters({
      isFinantialPending: "isFinantialPending",
    }),
  },
  methods: {},
  mounted() {},
};
</script>

<style>
.alert-container-payment { 
  position: absolute;
  z-index: 100025;
  width: 25%;
  min-width: 350px;
  right: 10px;
  top: 8px;
}

.payment-text {
  font-size: 20px;
  font-weight: 600;
}
@media only screen and (max-width: 375px){
  .alert-container-payment{
    width:  calc(100%/2)
  }
}
  

</style>
