<template>
  <div>
    <b-modal
      header-bg-variant="codemaps"
      header-text-variant="white"
      style="color: white"
      size="xl"
      title="📡 Fique por dentro!"
      v-model="modalShow"
      v-if="screenVisualized"
    >
      <div
        id="faq-data"
        class="ql-editor scroll-list-view styled-content"
        v-html="page.text"
      ></div>
      <template #modal-footer>
        <div
          class="w-100"
          style="display: flex; justify-content: space-between"
        >
          <b-form-checkbox
            name="checkbox-1"
            title="Ao marcar como visualizado esse vídeo não irá aparecer novamente"
            v-model="selected"
          >
            Declaro que visualizei e não desejo visualizar novamente.
          </b-form-checkbox>
          <b-button
            style="background-color: #28a745; border-color: #28a745"
            size="sm"
            class="ml-auto"
            @click="close"
          >
            Fechar
          </b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
import Request from "../request/request";
import { changeLogPath } from "../router/ApiPaths";
import Cookies from "js-cookie";
import { mapGetters } from "vuex";

export default {
  name: "TipScreen",
  extends: Request,
  data() {
    return {
      page: { text: "" },
      modalShow: true,
      selected: false,
      screenVisualized: false,
    };
  },
  computed: {
    ...mapGetters({
      isLogged: "isLogged",
    }),
  },
  watch: {
    isLogged(newValue) {
      if (newValue) this.find();
    },
  },
  methods: {
    find() {
      this.get(changeLogPath, null, true).then((data) => {
        if (data && data.id) {
          this.page = data;
          const screenVisualized = Cookies.get("screenVisualized");
          if (screenVisualized != "" + this.page.id) {
            this.screenVisualized = true;
          } else {
            this.page = null;
          }
        }
      });
    },
    close() {
      if (this.selected) {
        Cookies.set("screenVisualized", this.page.id, { expires: 60 });
      }
      this.screenVisualized = false;
    },
  },
};
</script>

<style lang="css" scoped>
@import "https://cdn.quilljs.com/1.3.6/quill.snow.css";
</style>
