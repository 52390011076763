export default (val) => {
  let text, color;
  if (val == "IOR") {
    text = "Integração OLT"; //historico
    color = "#3E8A10";
  } else if (val == "ION") {
    //alerta
    text = "Falha na integração OLT";
    color = "#F92F03";
  } else if (val == "UCP") {
    text = "Troca de senha";
    color = "#3E8A10";
  } else if (val == "UCR") {
    text = "Troca de perfil";
    color = "#3E8A10";
  } else if (val == "KMA") {
    text = "Extração de KML";
    color = "#3E8A10";
  } else if (val == "OLA") {
    text = "Falha na integração OLT";
    color = "#3E8A10";
  } else if (val == "CLA") {
    text = "Sobra de cabo mantida no cabo antigo";
    color = "#3E8A10";
  } else if (val == "OLH") {
    text = "Integração OLT";
    color = "#3E8A10";    
  } else if (val == "ERH") {
    text = "Integração ERP";
    color = "#3E8A10";    
  } else if (val == "ERA") {
    text = "Falha na integração ERP";
    color = "#3E8A10";    
  } else {
    text = "Falha na integração ERP";
    color = "#F92F03";
  }
  return { text, color };
};
