<template>
  <TemplatePopup
    :showMessage="saveDisabled"
    :fullScreen="fullScreen"
    :geometry="entity.geometry"
  >
  <div slot="header" class="header-container">
      <div class="header-title">
        <strong>Reserva Técnica</strong>
        <CreationDate :date="entity.created" />
      </div>
    </div>
    <div slot="body">
      <b-form-group label="Nome:">
        <b-form-input
          id="inputName"
          v-model="entity.name"
          :class="{ 'is-invalid': missingName }"
          max="50"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Identificação:">
        <b-form-input
          id="inputIdentification"
          v-model="entity.identifier"
          maxlength="50"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Tamanho Reserva (Metros):">
        <b-form-input
          id="inputReserve"
          v-model="entity.qtd_reserve"
          v-on:keypress="isNumberDecimal"
          :class="{ 'is-invalid': missingSize }"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Endereço:">
        <b-form-input
          id="inputAddress"
          v-model="entity.address"
          :class="{ 'is-invalid': missingAddress }"
          maxlength="255"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Endereços sugeridos:" v-if="isNew">
        <b-form-select id="addressList" v-model="entity.address" size="sm">
          <b-form-select-option
            v-for="entity in addressList"
            :value="entity.formatted_address"
            :key="entity.formatted_address"
          >
            {{ entity.formatted_address }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      <b-form-group
        label="Cabo da reserva técnica:"
        v-if="isNew || missingCable"
      >
        <b-form-select
          id="cableList"
          v-model="entity.cable"
          :class="{ 'is-invalid': missingCable }"
          @change="changeCable()"
          size="sm"
        >
          <b-form-select-option
            v-for="item in cableList"
            :value="item"
            :key="item.id"
          >
            {{ item.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

    </div>

    <div slot="button">
      <div class="text-center">
        <b-button-group class="my-2">
          <b-button
            id="btnDelete"
            @click.prevent="remove()"
            v-has-permission.permission="['RES', 'EX']"
            variant="muted"
            :disabled="isNew"
          >
            <b-icon icon="x-circle" aria-hidden="true"></b-icon>
            <p class="button-text">Excluir</p>
          </b-button>

          <b-button
            id="btnSave"
            @click.prevent="save()"
            :disabled="saveDisabled"
            v-has-permission.permission="['RES', 'AL']"
            variant="muted"
          >
            <b-icon icon="cursor" aria-hidden="true"></b-icon>
            <p class="button-text">Salvar</p>
          </b-button>
        </b-button-group>
      </div>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import {
  technicalReservePath,
  folderSimplePath,
} from "../../../router/ApiPaths";
import PopupBase from "./PopupBase";
import OnlyNumber from "../../../components/extra/OnlyNumber";
import CreationDate from "./CreationDate"

export default {
  name: "TechnicalReserve",
  extends: PopupBase,
  mixins: [OnlyNumber],
  components: {
    TemplatePopup,
    CreationDate
  },
  data() {
    return {
      folderList: [],
    };
  },
  mounted: function () {
    let path = technicalReservePath(this.folderId);

    // if (this.isProject) {
    //   path = technicalReserveProjectPath(this.getProject().id);
    // }

    this.init(path, true, true);
    this.getAllDirect(folderSimplePath).then((data) => {
      this.folderList = data;
    });
  },
  methods: {
    changeCable() {
      this.entity.folderId = this.entity.cable.folderId;
    },
  },
  computed: {
    missingName: function () {
      return !this.entity.name;
    },
    missingSize: function () {
      return !this.entity.qtd_reserve;
    },
    missingCable: function () {
      return !this.entity.id && !this.entity.cable;
    },
    missingAddress: function () {
      return !this.entity.address;
    },
    saveDisabled: function () {
      return this.missingName || this.missingCable || this.missingSize || !this.isDirty;
    },
  },
};
</script>
