<template>
  <b-nav-item-dropdown no-caret right title="Notificações" @shown="find" ref="dropdownNotificationRef">
    <template #button-content>
      <div class="notification-bell">
        <b-icon
          icon="bell-fill"
          style="color: #ffff"
        ></b-icon>
        <span class="badge" v-if="typeof notificationCount === 'number' && notificationCount > 0" :class="{ 'badge-animation-fade': typeof notificationCount === 'number' && notificationCount > 0 }"> {{ notificationCount }} </span>      
      </div>
    </template>
    <b-dropdown-text class="b-dropdown-tl-1">
      Notificações Recentes
    </b-dropdown-text>
    <b-dropdown-divider></b-dropdown-divider>
    <div class="calc-card-dropdown">
      <b-dropdown-text
        class="text-right text-all-notification"
        @click="markAllAsRead"
      >
        Marcar todas como lida
      </b-dropdown-text>
      <NotificationItem
        v-for="item in list"
        :key="item.id"
        :type="item.type"
        :date="item.date"
        :id="item.id"
        :isRead="item.isRead"
        @itemRead="handleItemRead"
      />
      <div
        class="d-flex justify-content-center align-items-center py-2"
        @click="closeDropdown"
      >
        <router-link
          id="linkNotification"
          class="text-all-notification text-decoration-none text-center"
          to="/notification"
        >
          Ver todas
        </router-link>
      </div>
    </div>
  </b-nav-item-dropdown>
</template>

<script>
import request from "../../request/request";
import NotificationItem from "./NotificationItem";
import { eventPageEventPath, eventUserPath } from "../../router/ApiPaths";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "NotificationsHeader",
  extends: request,
  components: {
    NotificationItem,
  },
  data() {
    return {
      list: [],
      limit: 10,
    };
  },
  computed: {
    ...mapGetters({
      notificationCount: "hasNotification",
    }),
  },
  methods: {
    async find() {
      this.get(`${eventPageEventPath}${this.limit}?category=A`).then((data) => {
        this.list = data;
      });
    },
    markAllAsRead() {
      const unreadItems = this.list.filter(item => !item.isRead);
      const updatePromises = unreadItems.map(item => this.post(eventUserPath, { eventId: item.id }));
      
      Promise.all(updatePromises).then(() => {
        this.list.forEach(item => item.isRead = true);
        this.updateNotificationCountInStore(this.notificationCount - unreadItems.length);
        this.closeDropdown();
      });
    },
    closeDropdown() {
      this.$refs.dropdownNotificationRef.$el.querySelector('.dropdown-toggle').click();
    },
    handleItemRead(id) {
      const item = this.list.find(notification => notification.id === id);
      if (item && !item.isRead) {
        item.isRead = true;
        this.updateNotificationCountInStore(this.notificationCount - 1);
      }
    },
    ...mapActions({
      setNotification: "setNotification",
    }),
    updateNotificationCountInStore(newCount) {
      this.setNotification(newCount);
    },
  },
};
</script>

<style scoped>
.b-dropdown-tl-1 {
  font-size: 17px;
  font-weight: 600 !important;
  color: #334257;
}

.calc-card-dropdown {
  width: 340px;
  max-height: calc(90vh - 100px);
  min-height: 300px;
  overflow-y: auto;
}

.b-dropdown-text {
  font-weight: 600;
}

.text-all-notification {
  font-size: 14px;
  color: #42526e;
  cursor: pointer;
}

.b-icon-animation-fade {
  color: #ff6600ff !important;
}
.notification-bell {
  position: relative;
}

.notification-bell .badge {
  position: absolute;
  top: -5px;
  right: -10px;
  background-color: #ff0000;
  color: #ffffff;
  border-radius: 50%;
  padding: 2px 6px;
  font-size: 10px;
}

.badge-animation-fade {
  animation: fade 1s infinite;
}

@keyframes fade {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}
</style>
