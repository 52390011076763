<template>
  <TemplatePopup :showMessage="saveDisabled" :fullScreen="fullScreen">
    <div slot="header">Comentário</div>
    <div slot="body">
      <form novalidate="true">
        <div class v-if="id">
          <label class="title-label">Data:</label>
          <label id="labelDate">{{ entity.date | formatDateTime }}</label>
        </div>

        <div class v-if="id">
          <label class="title-label">Usuário:</label>
          <label id="labelName">{{ entity.user.name }}</label>
        </div>

        <b-form-group label="Texto:">
          <b-form-textarea
            id="textArea"
            v-model="entity.text"
            :class="{ 'is-invalid': missingText }"
            placeholder="Digite um texto"
            maxlength="500"
            rows="5"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label="Pasta:">
        <b-form-select id="selectFolder" v-model="entity.folderId" size="sm">
          <b-form-select-option
            v-for="entityList in folderList"
            :value="entityList.id"
            :key="entityList.id"
          >
            {{ entityList.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>

      </form>
    </div>
    <div slot="button">
      <div slot="button">
      <div class="text-center">
        <b-button-group class="my-2">
          <b-button
            id="btnDelete"
            :disabled="isNew"
            @click.prevent="remove()"
            v-has-permission.permission="['CMM', 'EX']"
            variant="muted"
          >
            <b-icon icon="x-circle" aria-hidden="true"></b-icon>
            <p class="button-text">Excluir</p>
          </b-button>

          <b-button
            id="btnSave"
            :disabled="saveDisabled"
            @click.prevent="save()"
            v-has-permission.permission="['CMM', 'AL', 'CA']"
            variant="muted"
          >
            <b-icon icon="cursor" aria-hidden="true"></b-icon>
            <p class="button-text">Salvar</p>
          </b-button>
        </b-button-group>
      </div>
    </div>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import { commentMapPath, folderSimplePath } from "../../../router/ApiPaths";
import PopupBase from "./PopupBase";

export default {
  name: "CommentMap",
  extends: PopupBase,
  components: {
    TemplatePopup,
  },
  data() {
    return {
      entity: { user: {} },
      folderList: [],
    };
  },
  mounted: function () {
    let path = commentMapPath(this.folderId);
    this.init(path, false);
    this.getAllDirect(folderSimplePath).then((data) => {
      this.folderList = data;
    });
  },
  computed: {
    missingText: function () {
      return !this.entity.text;
    },
    saveDisabled: function () {
      return this.missingText || !this.isDirty;
    },
  },
};
</script>
