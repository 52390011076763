<template>
  <TemplatePopup
    :showMessage="saveDisabled"
    :fullScreen="fullScreen"
    :geometry="entity.geometry"
  >
    <div slot="header" class="header-container">
      <div class="header-title">
        <strong>Caixa de emendas</strong>
        <CreationDate :date="entity.created" />
      </div>
    </div>
    <div slot="body">
      <tabs>
        <tab title="Dados" :selected="true">
          <b-form-group label="Nome (TAG):">
            <b-form-input
              id="inputName"
              v-model="entity.name"
              :class="{ 'is-invalid': missingName }"
              maxlength="50"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Modelo:">
            <b-form-select
              id="modelList"
              v-model="entity.ceoModel.id"
              :class="{ 'is-invalid': missingModel }"
              size="sm"
            >
              <b-form-select-option
                v-for="entityList in modelList"
                :value="entityList.id"
                :key="entityList.id"
              >
                {{ entityList.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Endereço:">
            <b-form-input
              id="inputAddress"
              v-model="entity.address"
              maxlength="255"
              :class="{ 'is-invalid': missingAddress }"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group
            label="Endereços sugeridos:"
            v-if="isNew && entity.category != 'I'"
          >
            <b-form-select id="addressList" v-model="entity.address" size="sm">
              <b-form-select-option
                v-for="(address, index) in addressList"
                :value="address.formatted_address"
                :key="index"
              >
                {{ address.formatted_address }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Cabos sangria:" v-if="cableList.length">
            <b-form-select
              id="modelBleedCable"
              v-model="entity.cable"
              :disabled="!isNew"
              size="sm"
              title="Para fazer a sangria ou quebrar um cabo selecione na lista, caso não deseje quebrar o cabo deixe o campo em vazio"
            >
              <b-form-select-option :value="null">-</b-form-select-option>
              <b-form-select-option
                v-for="(cable, index) in cableList"
                :value="cable"
                :key="index"
              >
                {{ cable.name }}
              </b-form-select-option>
            </b-form-select>
          </b-form-group>

          <b-form-group label="Pasta:">
            <b-form-select
              id="selectFolder"
              v-model="entity.folderId"
              size="sm"
            >
              <b-form-select-option
                v-for="entityList in folderList"
                :value="entityList.id"
                :key="entityList.id"
              >
                {{ entityList.name }}
              </b-form-select-option>
            </b-form-select>

          </b-form-group>
          <b-form-group label="Cor:">
            <b-form-select id="colorList" v-model="entity.color" size="sm">
              <b-form-select-option value="yellow"> Amarela </b-form-select-option>
              <b-form-select-option value="blue"> Azul </b-form-select-option>
              <b-form-select-option value="gray"> Cinza </b-form-select-option>
              <b-form-select-option value="orange"> Laranja </b-form-select-option>
              <b-form-select-option value="green"> Verde </b-form-select-option>
              <b-form-select-option value="red"> Vermelha </b-form-select-option>
            </b-form-select>
          </b-form-group>
          
          <b-form-group label="Bloco:" v-if="entity.category === 'I'">
            <b-form-input
              id="inputBlock"
              v-model="entity.block"
              maxlength="50"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <b-form-group label="Andar:" v-if="entity.category === 'I'">
            <b-form-input
              type="number"
              id="inputFloor"
              v-model="entity.floor"
              size="sm"
            ></b-form-input>
          </b-form-group>

          <div class="row mt-2" v-if="!isNew">
            <div class="col-md-6 col-sm-12 mb-1">
              <CardUsage
                id="usage"
                text="Taxa de utilização:"
                :value="entity.usage"
              />
            </div>
            <div class="col-md-6 col-sm-12 mb-1">
              <Card
                id="avaliableEquipments"
                text="Portas disponíveis:"
                :value="entity.avaliableEquipments"
              />
            </div>
          </div>
        </tab>
        <tab
          id="tabCables"
          title="Cabos"
          v-if="!isNew"
          :permission="['CAB', 'VI']"
        >
          <Cables :equipmentId="entity.id" />
        </tab>
        <tab
          id="tabSplitter"
          title="Splitter"
          v-if="!isNew"
          :permission="['SPL', 'VI']"
        >
          <Splitter
            :equipmentId="entity.id"
            :updateAction="null"
            :showAttendance="false"
          />
        </tab>
      </tabs>
    </div>
    <div slot="button">
      <div class="text-center">
        <b-button-group class="my-2">
          <b-button
            id="btnOtdr"
            :disabled="isNew"
            @click.prevent="OTDR()"
            v-has-permission.permission="['OTR', 'VI']"
            variant="muted"
          >
            <b-icon icon="file-ruled" aria-hidden="true"></b-icon>
            <p class="button-text">OTDR</p>
          </b-button>
          <b-button
            id="btnActing"
            :disabled="isNew"
            @click.prevent="drawnLimit()"
            v-has-permission.permission="['DRA', 'VI']"
            variant="muted"
          >
            <b-icon icon="bounding-box-circles" aria-hidden="true"></b-icon>
            <p class="button-text">Atuação</p>
          </b-button>

          <b-button
            id="btnReport"
            :disabled="isNew"
            @click.prevent="connectionReport()"
            v-has-permission.permission="['REL', 'VI']"
            variant="muted"
          >
            <b-icon icon="bar-chart-line-fill" aria-hidden="true"></b-icon>
            <p class="button-text">Relatório</p>
          </b-button>

          <b-button
            id="btnComments"
            :disabled="isNew"
            @click.prevent="comment()"
            v-has-permission.permission="['COM', 'VI']"
            variant="muted"
          >
            <b-icon icon="chat-square-dots" aria-hidden="true"></b-icon>
            <p class="button-text">Comentários</p>
          </b-button>

          <b-button
            id="btnImage"
            :disabled="isNew"
            @click.prevent="image()"
            v-has-permission.permission="['IMG', 'VI']"
            variant="muted"
          >
            <b-icon icon="image" aria-hidden="true"></b-icon>
            <p class="button-text">Imagem</p>
          </b-button>

          <b-button
            id="btnConnections"
            :disabled="isNew"
            @click.prevent="conexao()"
            v-has-permission.permission="['CON', 'VI']"
            variant="muted"
          >
            <b-icon icon="diagram3" aria-hidden="true"></b-icon>
            <p class="button-text">Conexões</p>
          </b-button>

          <b-button
            id="btnDelete"
            :disabled="isNew"
            @click.prevent="remove()"
            v-has-permission.permission="['CAI', 'EX']"
            variant="muted"
          >
            <b-icon icon="x-circle" aria-hidden="true"></b-icon>
            <p class="button-text">Excluir</p>
          </b-button>

          <b-button
            id="btnSave"
            @click.prevent="save()"
            v-has-permission.permission="['CAI', 'AL', 'CA']"
            variant="muted"
            :disabled="saveDisabled"
          >
            <b-icon icon="cursor" aria-hidden="true"></b-icon>
            <p class="button-text">Salvar</p>
          </b-button>
        </b-button-group>
      </div>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import Splitter from "./Tabs/Splitter";
import Cables from "./Tabs/Cables";
import {
  amendmentPath,
  amendmentModelSimplePath,
  folderSimplePath,
} from "../../../router/ApiPaths";
import PopupBase from "./PopupBase";
import Card from "../../../components/extra/Card";
import CardUsage from "../../../components/extra/CardUsage";
import Tabs from "./Tabs/Tabs";
import Tab from "./Tabs/Tab";
import CreationDate from "./CreationDate"

export default {
  name: "AmendmentMap",
  extends: PopupBase,
  components: {
    TemplatePopup,
    Splitter,
    Cables,
    Card,
    CardUsage,
    Tabs,
    Tab,
    CreationDate
  },
  data() {
    return {
      modelList: [],
      folderList: [],
    };
  },
  beforeMount() {
    this.entity = {
      id: null,
      ceoModel: { id: null },
      cable: null,
      color: "yellow",
      category: this.extra && this.extra.category ? this.extra.category : "E",
      address:
        this.extra && this.extra.address
          ? this.extra.address
          : this.entity.address,
      equipmentExternalId:
        this.extra && this.extra.equipmentExternalId
          ? this.extra.equipmentExternalId
          : undefined,
      positionDiagram:
        this.extra && this.extra.positionDiagram
          ? this.extra.positionDiagram
          : undefined,
    };
  },
  mounted() {
    let path = amendmentPath(this.folderId);

    this.getAllDirect(amendmentModelSimplePath).then((data) => {
      this.modelList = data;
    });

    this.getAllDirect(folderSimplePath).then((data) => {
      this.folderList = data;
    });

    if (this.extra && this.extra.category === "I") this.init(path);
    else this.init(path, true, this.isNew);
  },
  computed: {
    missingModel: function () {
      return this.entity.ceoModel && !this.entity.ceoModel.id;
    },
    missingName: function () {
      return !this.entity.name;
    },
    missingAddress: function () {
      return !this.entity.address;
    },
    saveDisabled: function () {
      return this.missingName || this.missingModel || this.missingAddress || !this.isDirty;
    },
    bgc() {
      this.colors.hex;
      return this.colors.hex;
    }
  },
  methods: {
    updateValue(value) {
      this.colors = value;
      this.entity.color = value.hex;
    }
  }
};
</script>
