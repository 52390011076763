import Vue from 'vue'

export const sucessMessageListener = 'successMessage'
export const errorMessageListener = 'errorMessage'
export const infoMessageListener = 'infoMessage'
export const mapActionListener = 'mapAction'
export const actionListener = 'action'
export const buildingActionListener = 'buildingAction'
export const changeConnection = 'changeConnection'
export const cableClientConnection = 'cableClientConnection'

export const centerMapSimple = 'CM'
export const centerMapPoint = 'P'
export const clearMap = 'C'
export const updateMap = 'U'
export const addMap = 'A'
export const removeMap = 'R'
export const updateAllMap = 'UA'
export const updateAllRepositionMap = 'UAR'
export const updateList = 'UL'
export const addLineMap = 'L'
export const addList = 'AL'
export const refreshCables = 'RFC'
export const selectTool = 'selecttools'
export const addOTDRMap = 'O'
export const drawElementAction = 'drawElement'
export const refreshLimit = 'RL'
export const closeCableUsageLegend = 'CCUL'
export const closeClientSignalLegend = 'CCSL'
export const closeHeatMapLegend = 'CHML'

export const openProject = 'OPR'
export const openFolder = 'OFD'
export const closeProject = 'CPR'
export const closeFolder = 'CFD'
export const newPopup = 'newpopup'
export const closePopup = 'closepopup'
export const closeAllPopup = 'closeallpopup'
export const toolsMap = 'toolsmap'
export const logout = 'logout'
export const counter = 'counter'


export const messageBus = new Vue()

export const sucessMessage = (message) => {
    messageBus.$emit(sucessMessageListener, message)
}

export const errorMessage = (message) => {
    messageBus.$emit(errorMessageListener, message)
}

export const infoMessage = (message) => {
    messageBus.$emit(infoMessageListener, message)
}

export const sendContinuosAction = (action, data) => {
    messageBus.$emit(action, data)
}

export const getContinuosAction = (action, callback) => {
    messageBus.$off(action, () => { })
    messageBus.$on(action, (data) => {
        callback(data)
    })
}

export const sendAction = (data) => {
    messageBus.$emit(actionListener, data)
}

export const getAction = (callback) => {
    messageBus.$once(actionListener, (data) => {
        callback(data)
    })
}

export const sendMapAction = (type, data) => {
    messageBus.$emit(mapActionListener, {
        type,
        payload: data
    })
}

export const getMapAction = (callback) => {
    messageBus.$off(mapActionListener)
    messageBus.$on(mapActionListener, (data) => {
        callback(data)
    })
}

export const sendBuildingAction = (type, data) => {
    messageBus.$emit(buildingActionListener, {
        type,
        payload: data
    })
}

export const getBuildingAction = (callback) => {
    messageBus.$off(buildingActionListener)
    messageBus.$on(buildingActionListener, (data) => {
        callback(data)
    })
}

export const openPopupBus = (data) => {
    messageBus.$emit(newPopup, {
        component: data.component,
        props: data.props
    })
}

export const closePopupBus = () => {
    messageBus.$emit(closePopup)
}

export const closeAllPopupBus = () => {
    messageBus.$emit(closeAllPopup)
}

export const setSelectMap = () => {
    sendMapAction(selectTool)
}

export const sendLogout = () => {
    sendContinuosAction(logout)
}

export const getLogout = (callback) => {
    getContinuosAction(logout, callback)
}

export const sendCounter = (data) => {
    sendContinuosAction(counter, data)
}

export const getCounter = (callback) => {
    getContinuosAction(counter, callback)
}