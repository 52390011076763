import Router from "vue-router";
import store from "../store";
import { tokenStore } from "../components/extra/LocalStorage";
import { HTTP, setTokenHeader } from "../request/request";
import { autoLoginPath, technicalReserveFolderPath } from "../router/ApiPaths";

import Login from "../view/login/Login";
import ForgotPassword from "../view/login/ForgotPassword";
import { hasPermission } from "../components/extra/hasPermission";
import socket from "../socket"
import CeoReport from "@/view/map/secundary/report/CeoReport.vue";


const Main = () => import("../view/main/Main");

const Map = () => import("../view/map/map/Map");
const ManufacturerList = () => import("../view/map/secundary/ManufacturerList");
const ManufacturerInput = () =>
  import("../view/map/secundary/ManufacturerInput");
const CableModelList = () => import("../view/map/secundary/CableModelList");
const CableModelInput = () => import("../view/map/secundary/CableModelInput");
const ConnectorModelList = () =>
  import("../view/map/secundary/ConnectorModelList");
const ConnectorModelInput = () =>
  import("../view/map/secundary/ConnectorModelInput");
const SplitterModelList = () =>
  import("../view/map/secundary/SplitterModelList");
const SplitterModelInput = () =>
  import("../view/map/secundary/SplitterModelInput");
const SwitchModelList = () =>
  import("../view/map/secundary/SwitchModelList");
const SwitchModelInput = () =>
  import("../view/map/secundary/SwitchModelInput");
const AmendmentModelList = () =>
  import("../view/map/secundary/AmendmentModelList");
const AmendmentModelInput = () =>
  import("../view/map/secundary/AmendmentModelInput");
const CtoModelList = () => import("../view/map/secundary/CtoModelList");
const CtoModelInput = () => import("../view/map/secundary/CtoModelInput");
const DioModelList = () => import("../view/map/secundary/DioModelList");
const DioModelInput = () => import("../view/map/secundary/DioModelInput");
const IpList = () => import("../view/map/secundary/IpList");
const IpInput = () => import("../view/map/secundary/IpInput");
const WebReport = () => import("../view/map/secundary/report/WebReport");
const ChangePassword = () => import("../view/map/secundary/ChangePassword");
const UserList = () => import("../view/map/secundary/UserList");
const UserInput = () => import("../view/map/secundary/UserInput");
const CtoReport = () => import("../view/map/secundary/report/CtoReport");
const PoleReport = () => import("../view/map/secundary/report/PoleReport");

const OltModelList = () => import("../view/map/secundary/OltModelList");
const OltModelInput = () => import("../view/map/secundary/OltModelInput");
const BoardModelList = () => import("../view/map/secundary/BoardModelList");
const BoardModelInput = () => import("../view/map/secundary/BoardModelInput");
const ClientReport = () => import("../view/map/secundary/report/ClientReport");
const CordoalhaModelList = () =>
  import("../view/map/secundary/CordoalhaModelList");
const CordoalhaModelInput = () =>
  import("../view/map/secundary/CordoalhaModelInput");
const HardwareKitList = () => import("../view/map/secundary/HardwareKitList");
const HardwareKitInput = () => import("../view/map/secundary/HardwareKitInput");
const HardwareItemsList = () =>
  import("../view/map/secundary/HardwareItemsList");
const HardwareItemsInput = () =>
  import("../view/map/secundary/HardwareItemsInput");
const ConfigurationCompany = () =>
  import("../view/map/secundary/ConfigurationCompany");
const Funnel = () => import("../view/map/secundary/Funnel");
const WebOrganizer = () => import("../view/map/secundary/weborganizer/WebOrganizer");
const CtoWebOrganizer = () => import("@/view/map/secundary/weborganizer/CtoWebOrganizer.vue");
const CeoWebOrganizer = () => import("@/view/map/secundary/weborganizer/CeoWebOrganizer.vue");
const PopWebOrganizer = () => import("@/view/map/secundary/weborganizer/PopWebOrganizer.vue");
const ClientWebOrganizer = () => import("@/view/map/secundary/weborganizer/ClientWebOrganizer.vue");
const CableWebOrganizer = () => import("@/view/map/secundary/weborganizer/CableWebOrganizer.vue");
const TechnicalReserveWebOrganizer = () => import("@/view/map/secundary/weborganizer/TechnicalReserveWebOrganizer.vue");
const PoleWebOrganizer = () => import("@/view/map/secundary/weborganizer/PoleWebOrganizer.vue");
const PolygonWebOrganizer = () => import("@/view/map/secundary/weborganizer/PolygonWebOrganizer.vue");
const VirtualCircuitWebOrganizer = () => import("@/view/map/secundary/weborganizer/VirtualCircuitWebOrganizer.vue");
const CordoalhaWebOrganizer = () => import("@/view/map/secundary/weborganizer/CordoalhaWebOrganizer.vue");
  
const CableReport = () => import("../view/map/secundary/report/CableReport");
const QRCodeHost = () => import("../view/map/secundary/QRCodeHost");
const SNBind = () => import("../view/map/secundary/SNBind");
const ClientSignalReport = () =>
  import("../view/map/secundary/report/ClientSignalReport");
const ProfileList = () => import("../view/map/secundary/ProfileList");
const ProfileInput = () => import("../view/map/secundary/ProfileInput");
const FaqView = () => import("../view/faq/FaqView");
const FaqData = () => import("../view/faq/FaqData");
// const VideoList = () => import('../view/video/VideoList')
// const VideoView = () => import('../view/video/VideoView')

const Viability = () => import("../view/viability/Viability");
const NewViability = () => import("../view/releaseProducts/NewViability");
const Activation = () => import("../view/general/Activation");
const OltModule = () => import("../view/general/OltModule");
const OltDataList = () => import("../view/map/secundary/OltDataList");

const Importer = () => import("../view/importer/Importer");
const KmlExtractList = () => import("../view/importer/KmlExtractList.vue");
const KmlExtractInput = () => import("../view/importer/KmlExtractInput.vue");

// const TicketList = () => import("../view/ticket/TicketList");
// const TicketInput = () => import("../view/ticket/TicketInput");
const Payments = () => import("../view/map/secundary/Payments");
const NotificationList = () => import("../view/notification/NotificationList");
const LoggingList = () => import("../view/logging/LoggingList");


const router = new Router({
  base: window.location.pathName,
  routes: [
    {
      path: "/",
      name: "main",
      component: Main
    },
    {
      path: "/login",
      name: "login",
      component: Login
    },
    {
      path: "/map",
      name: "map",
      component: Map,
      meta: {
        permission: ["AMA", "VI"]
      }
    },
    {
      path: "/viability",
      name: "viability",
      component: Viability,
      meta: {
        permission: ["AVA", "VI"]
      }
    },
    {
      path: "/newViability",
      name: "newViability",
      component: NewViability
    },
    {
      path: "/activation",
      name: "activation",
      component: Activation,
      meta: {
        permission: ["ATI", "VI"]
      }
    },
    {
      path: "/oltModule",
      name: "oltModule",
      component: OltModule,
      meta: {
        permission: ["OLT", "VI"]
      }
    },
    {
      path: "/olt/:id/oltdatalist",
      name: "oltDataList",
      component: OltDataList,
      meta: {
        permission: ["OLT", "VI"]
      }
    },
    {
      path: "/manufacturer",
      name: "manufacturerList",
      component: ManufacturerList,
      meta: {
        permission: ["CFB", "VI"]
      }
    },
    {
      path: "/manufacturer/:id",
      name: "manufacturerInput",
      component: ManufacturerInput,
      meta: {
        permission: ["CFB", "VI"]
      }
    },
    {
      path: "/cablemodel",
      name: "cablemodelList",
      component: CableModelList,
      meta: {
        permission: ["CMB", "VI"]
      }
    },
    {
      path: "/cablemodel/:id",
      name: "cablemodelInput",
      component: CableModelInput,
      meta: {
        permission: ["CMB", "VI"]
      }
    },
    {
      path: "/conectormodel",
      name: "conectorModelList",
      component: ConnectorModelList,
      meta: {
        permission: ["CMC", "VI"]
      }
    },
    {
      path: "/conectormodel/:id",
      name: "conectorModelInput",
      component: ConnectorModelInput,
      meta: {
        permission: ["CMC", "VI"]
      }
    },
    {
      path: "/splittermodel",
      name: "splitterModelList",
      component: SplitterModelList,
      meta: {
        permission: ["CMS", "VI"]
      }
    },
    {
      path: "/splittermodel/:id",
      name: "splitterModelInput",
      component: SplitterModelInput,
      meta: {
        permission: ["CMS", "VI"]
      }
    },
    {
      path: "/switchmodel",
      name: "switchModelList",
      component: SwitchModelList,
      meta: {
        permission: ["SMO", "VI"]
      }
    },
    {
      path: "/switchmodel/:id",
      name: "switchModelInput",
      component: SwitchModelInput,
      meta: {
        permission: ["SMO", "VI"]
      }
    },
    {
      path: "/amendmentmodel",
      name: "amendmentModelList",
      component: AmendmentModelList,
      meta: {
        permission: ["CME", "VI"]
      }
    },
    {
      path: "/amendmentmodel/:id",
      name: "amendmentModelInput",
      component: AmendmentModelInput,
      meta: {
        permission: ["CME", "VI"]
      }
    },
    {
      path: "/ctomodel",
      name: "ctoModelList",
      component: CtoModelList,
      meta: {
        permission: ["CMT", "VI"]
      }
    },
    {
      path: "/ctomodel/:id",
      name: "ctoModelInput",
      component: CtoModelInput,
      meta: {
        permission: ["CMT", "VI"]
      }
    },
    {
      path: "/diomodel",
      name: "dioModelList",
      component: DioModelList,
      meta: {
        permission: ["CMD", "VI"]
      }
    },
    {
      path: "/diomodel/:id",
      name: "dioModelInput",
      component: DioModelInput,
      meta: {
        permission: ["CMD", "VI"]
      }
    },
    {
      path: "/ip",
      name: "ipList",
      component: IpList,
      meta: {
        permission: ["AIP", "VI"]
      }
    },
    {
      path: "/ip/:id",
      name: "ipInput",
      component: IpInput,
      meta: {
        permission: ["AIP", "VI"]
      }
    },
    {
      path: "/webreport",
      name: "webreport",
      component: WebReport,
      meta: {
        permission: ["RER", "VI"]
      }
    },
    {
      path: "/changepassword",
      name: "changepassword",
      component: ChangePassword
    },
    {
      path: "/forgotpassword",
      name: "forgotpassword",
      component: ForgotPassword
    },
    {
      path: "/user",
      name: "userList",
      component: UserList,
      meta: {
        permission: ["USU", "VI"]
      }
    },
    {
      path: "/user/:id",
      name: "userInput",
      component: UserInput,
      meta: {
        permission: ["USU", "VI"]
      }
    },
    {
      path: "/ctoreport",
      name: "ctoReport",
      component: CtoReport,
      meta: {
        permission: ["RET", "VI"]
      }
    },
    {
      path: "/ceoreport",
      name: "ceoReport",
      component: CeoReport,
      meta: {
        permission: ["REO", "VI"]
      }
    },
    {
      path: "/polereport",
      name: "poleReport",
      component: PoleReport,
      meta: {
        permission: ["RPO", "VI"]
      }
    },
    {
      path: "/clientreport",
      name: "clientReport",
      component: ClientReport,
      meta: {
        permission: ["REC", "VI"]
      }
    },
    {
      path: "/oltmodel",
      name: "oltModelList",
      component: OltModelList,
      meta: {
        permission: ["CMO", "VI"]
      }
    },
    {
      path: "/oltmodel/:id",
      name: "oltModelInput",
      component: OltModelInput,
      meta: {
        permission: ["CMO", "VI"]
      }
    },
    {
      path: "/boardmodel",
      name: "boardModelList",
      component: BoardModelList,
      meta: {
        permission: ["CMP", "VI"]
      }
    },
    {
      path: "/boardmodel/:id",
      name: "boardModelInput",
      component: BoardModelInput,
      meta: {
        permission: ["CMP", "VI"]
      }
    },
    {
      path: "/cordoalhamodel",
      name: "cordoalhaModelList",
      component: CordoalhaModelList,
      meta: {
        permission: ["CML", "VI"]
      }
    },
    {
      path: "/cordoalhamodel/:id",
      name: "cordoalhaModelInput",
      component: CordoalhaModelInput,
      meta: {
        permission: ["CML", "VI"]
      }
    },
    {
      path: "/hardwarekit",
      name: "hardwareKitlList",
      component: HardwareKitList,
      meta: {
        permission: ["CKF", "VI"]
      }
    },
    {
      path: "/hardwarekit/:id",
      name: "hardwareKitlInput",
      component: HardwareKitInput,
      meta: {
        permission: ["CKF", "VI"]
      }
    },
    {
      path: "/hardwareitems",
      name: "hardwareItemslList",
      component: HardwareItemsList,
      meta: {
        permission: ["CKF", "VI"]
      }
    },
    {
      path: "/hardwareitems/:id",
      name: "hardwareItemslInput",
      component: HardwareItemsInput,
      meta: {
        permission: ["CKF", "VI"]
      }
    },
    {
      path: "/configurationcompany",
      name: "configurationCompany",
      component: ConfigurationCompany,
      meta: {
        permission: ["COE", "VI"]
      }
    },
    {
      path: "/funnel",
      name: "funnel",
      component: Funnel,
      meta: {
        permission: ["FUN", "VI"]
      }
    },
    {
      path: "/webOrganizer",
      component: WebOrganizer,
      meta: {
        permission: ["ORG", "VI"]
      },
      children:[
        {
          path: "",
          name: "ctoWebOrganizer",
          component: CtoWebOrganizer
        },
        {
          path: "/ceo",
          name: "ceoWebOrganizer",
          component: CeoWebOrganizer
        },
        {
          path: "/pop",
          name: "popWebOrganizer",
          component: PopWebOrganizer
        },
        {
          path: "/client",
          name: "clientWebOrganizer",
          component: ClientWebOrganizer
        },
        {
          path: "/cable",
          name: "cableWebOrganizer",
          component: CableWebOrganizer
        },
        {
          path: "/technicalReserve",
          name: "technicalReserveWebOrganizer",
          component: TechnicalReserveWebOrganizer
        },
        {
          path: "/pole",
          name: "poleWebOrganizer",
          component: PoleWebOrganizer
        },
        {
          path: "/polygon",
          name: "polygonWebOrganizer",
          component: PolygonWebOrganizer
        },
        {
          path: "/virtualCircuit",
          name: "virtualCircuitWebOrganizer",
          component: VirtualCircuitWebOrganizer
        },
        {
          path: "/cordoalha",
          name: "cordoalhaWebOrganizer",
          component: CordoalhaWebOrganizer
        },
      ]
    },
    {
      path: "/cablereport",
      name: "cableReport",
      component: CableReport,
      meta: {
        permission: ["REB", "VI"]
      }
    },
    {
      path: "/qrcodehost",
      name: "QRCodeHost",
      component: QRCodeHost
    },
    {
      path: "/snbind",
      name: "SNBind",
      component: SNBind,
      meta: {
        permission: ["VSN", "VI"]
      }
    },
    {
      path: "/clientsignalreport",
      name: "clientSignalReport",
      component: ClientSignalReport,
      meta: {
        permission: ["REI", "VI"]
      }
    },
    {
      path: "/profile",
      name: "profileList",
      component: ProfileList,
      meta: {
        permission: ["PRO", "VI"]
      }
    },
    {
      path: "/profile/:id",
      name: "profileInput",
      component: ProfileInput,
      meta: {
        permission: ["PRO", "VI"]
      }
    },
    {
      path: "/importer",
      name: "importer",
      component: Importer,
      meta: {
        permission: ["KML", "VI"]
      }
    },
    {
      path: "/faq",
      name: "faqview",
      component: FaqView,
      children: [
        {
          path: "/faq/:id?",
          name: "faqData",
          component: FaqData
        },
      ]
    },
    // {
    //   path: "/video",
    //   name: "videoList",
    //   component: VideoList,
    //   children: [
    //     {
    //       path: "/video/:id?",
    //       name: "videoView",
    //       component: VideoView
    //     },
    //   ]
    // },
    // {
    //   path: "/ticket",
    //   name: "ticketList",
    //   component: TicketList
    // },
    // {
    //   path: "/ticket/new",
    //   name: "ticketInput",
    //   component: TicketInput
    // },
    {
      path: "/payments",
      name: "payments",
      component: Payments,
      meta: {
        permission: ["PAG", "VI"]
      }
    },
    {
      path: "/notification",
      name: "notification",
      component: NotificationList
    },
    {
      path: "/logging",
      name: "logging",
      component: LoggingList,
      meta: {
        permission: ["LOG", "VI"]
      }
    },
    {
      path: "/kml",
      name: "kml",
      component: KmlExtractList,
      meta: {
        permission: ["KME", "VI"]
      }
    },
    {
      path: "/kml/extract",
      name: "kmlExtract",
      component: KmlExtractInput,
      meta: {
        permission: ["KME", "VI"]
      }
    },
    {
      path: '/:pathMatch(.*)*',
      redirect: "/"
    }
  ]
});

const validateToken = token => {
  setTokenHeader(token);
  return HTTP.get(autoLoginPath);
};

router.beforeEach((to, from, next) => {
  store.dispatch("startLoading");

  if (store.getters.isLogged && to.meta.permission && !hasPermission(to.meta.permission)) {
    next('/');
    store.dispatch("endLoading");
  } else {
    if (
      !store.getters.isLogged &&
      (to.name === "login" || to.name === "forgotpassword")
    ) {
      next();
      store.dispatch("endLoading");
    } else if (!store.getters.isLogged) {
      store.dispatch("startLoading");
      let localToken;

      if (to.query && to.query.t) {
        localToken = to.query.t;
      } else {
        localToken = tokenStore.get();
      }

      if (localToken) {
        validateToken(localToken)
          .then(response => {
            store.dispatch("setLogin", {
              user: response.data,
              token: localToken
            });
            socket(response.data.private, response.data.global);
            next();
            store.dispatch("endLoading");
          })
          .catch(() => {
            next("/login");
            store.dispatch("endLoading");
          });
      } else {
        next("/login");
      }
    } else {
      next();
    }
  }
});

router.beforeResolve((to, from, next) => {
  store.dispatch("endLoading");
  next();
});

export default router;
