<template>
  <div class="mx-3 border-bottom" @click="markAsRead">
    <b-row v-b-toggle="`accordion-${id}`" class="p-2">
      <b-col cols="2" class="d-flex align-items-center">
        <b-icon
          ref="icon"
          icon="bell-fill"
          scale="1.3"
          :style="{ color: color }"
          :class="{ 'b-icon-animation-fade': !isRead }"
        ></b-icon>
      </b-col>
      <b-col cols="10">
        <div>
          <p class="m-0 text-notification-item">{{ text }}</p>
          <small class="text-date"> {{ date | formatTimestamp }}</small>
        </div>
      </b-col>
    </b-row>

    <b-collapse
      :id="`accordion-${id}`"
      accordion="accordionNotificationItem"
      role="tabpanel"
      @show="findTextEvent"
    >
      <b-card-body>
        <b-card-text class="text-detail">
          <div class="m-0 text-notification-item" v-html="detail"></div>
        </b-card-text>
      </b-card-body>
    </b-collapse>
  </div>
</template>

<script>
import request from "../../request/request";
import { eventPath } from "../../router/ApiPaths";
import EventTypeText from "../extra/EventTypeText";

export default {
  name: "NotificationItem",
  extends: request,
  props: ["id", "type", "date", "isRead"],
  data() {
    return {
      color: "secondary",
      text: "Pendente",
      detail: "",
    };
  },
  watch: {
    type(val) {
      this.updateText(val);
    },
    isRead(newValue) {
      if (newValue) {
        this.$nextTick(() => {
          this.$refs.icon.classList.remove('b-icon-animation-fade');
        });
      }
    },
  },
  mounted() {
    this.updateText(this.type);
  },
  methods: {
    updateText(val) {
      const result = EventTypeText(val);
      this.text = result.text;
      this.color = result.color;
    },
    async findTextEvent() {
      const data = await this.get(`${eventPath}/${this.id}`);
      this.detail = data.text;
    },
    markAsRead() {
      if (!this.isRead) {
        this.$emit('itemRead', this.id);
      }
    },
  },
};
</script>

<style>
.text-notification-item {
  font-weight: 600;
  text-align: justify;
}
.text-date {
  color: #5a5a5a;
}

.text-detail {
  overflow: hidden;
  position: relative;
  max-height: 12em;
}

.text-detail:before {
    content: '';
    position: absolute;
    right: 0px;
    bottom: 0;
    background-color: #ffffff;
    padding: 0 0px 0 0.25em;
}

.b-icon-animation-fade {
  animation: fade 1s infinite;
}

@keyframes fade {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}
</style>
