<template>
  <TemplatePopup :showMessage="false" :fullScreen="fullScreen">
    <div slot="header">Ponto de Manutenção</div>
    <div slot="body">
      <b-form-group label="Ordem de serviço:">
        <b-form-input
          id="inputOrderService"
          v-model="entity.orderService"
          :class="{ 'is-invalid': missingOrderService }"
          maxlength="50"
          size="sm"
        ></b-form-input>
      </b-form-group>

      <b-form-group label="Comentário:">
        <b-form-textarea
          id="inputComment"
          placeholder="Digite um texto"
          v-model="entity.comment"
          rows="5"
          max-rows="6"
        ></b-form-textarea>
      </b-form-group>

      <b-form-group label="Pasta:">
        <b-form-select id="selectFolder" v-model="entity.folderId" size="sm">
          <b-form-select-option
            v-for="entityList in folderList"
            :value="entityList.id"
            :key="entityList.id"
          >
            {{ entityList.name }}
          </b-form-select-option>
        </b-form-select>
      </b-form-group>
    </div>

    <div slot="button">
      <div class="text-center">
        <b-button-group class="my-2">
          <b-button
            id="btnImg"
            :disabled="isNew"
            @click.prevent="image('maintaince')"
            v-has-permission.permission="['IMG', 'VI']"
            variant="muted"
          >
            <b-icon icon="image" aria-hidden="true"></b-icon>
            <p class="button-text">Imagem</p>
          </b-button>

          <b-button
            id="btnDelete"
            :disabled="isNew"
            @click.prevent="remove()"
            v-has-permission.permission="['MAI', 'EX']"
            variant="muted"
          >
            <b-icon icon="x-circle" aria-hidden="true"></b-icon>
            <p class="button-text">Excluir</p>
          </b-button>

          <b-button
            id="btnSave"
            :disabled="saveDisabled"
            @click.prevent="save()"
            v-has-permission.permission="['MAI', 'AL']"
            variant="muted"
          >
            <b-icon icon="cursor" aria-hidden="true"></b-icon>
            <p class="button-text">Salvar</p>
          </b-button>
        </b-button-group>
      </div>
    </div>
  </TemplatePopup>
</template>

<script>
import TemplatePopup from "../../../template/TemplatePopup";
import {
  maintenancePointPath,
  folderSimplePath,
} from "../../../router/ApiPaths";
import PopupBase from "./PopupBase";

export default {
  name: "MaintenancePoint",
  extends: PopupBase,
  data() {
    return {
      folderList: [],
    };
  },
  components: {
    TemplatePopup,
  },
  beforeMount() {},
  mounted: function () {
    let path = maintenancePointPath(this.folderId);
    this.init(path, false);

    this.getAllDirect(folderSimplePath).then((data) => {
      this.folderList = data;
    });
  },
  computed: {
    missingOrderService: function () {
      return !this.entity.orderService;
    },
    saveDisabled: function () {
      return this.missingOrderService || !this.isDirty;
    },
  },
};
</script>
