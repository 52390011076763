<template>
  <div class="popUpBody" @focus="alert('focus')">
    <div class="modal-header modal-header-popup">
      <strong>
        <slot name="header"></slot>
      </strong>
      <div>
        <b-button
          v-if="geometry"
          class="c-pointer p-0 pr-2"
          variant="transparent"
          @click.prevent="copyPosition()"
          title="Copiar a posição do elemento no formato Google"
        >
          <i class="fa fa-globe" aria-hidden="true"></i>
        </b-button>

        <b-button
          class="c-pointer p-0 pr-2"
          variant="transparent"
          @click.prevent="showMap()"
          title="Minimizar a tela"
          v-if="!disableMapButton"
        >
          <b-icon icon="dash" variant="dark"></b-icon>
        </b-button>

        <b-button
          class="c-pointer p-0"
          variant="transparent"
          @click.prevent="exit()"
          size="lg"
          title="Fechar"
        >
          <b-icon icon="x" variant="dark"></b-icon>
        </b-button>
      </div>
    </div>

    <div class="modal-body" :class="{ 'modal-body-full': fullScreen }">
      <slot name="body"></slot>
      <div class="alert-message pt-3" v-if="showMessage">
        <b-icon icon="exclamation-circle" variant="danger"></b-icon> Campos em vermelho são obrigatórios.
      </div>
    </div>

    <slot
      class="modal-footer p-0"
      :class="{ ' modal-footer-full': fullScreen }"
      name="legend"
    ></slot>
    <slot
      class="modal-footer p-0"
      :class="{ ' modal-footer-full': fullScreen }"
      name="button"
    ></slot>
  </div>
</template>

<script>
import { minimizeAll } from "../components/extra/HideModals";
import {
  closePopupBus,
  sucessMessage,
  errorMessage,
} from "../components/MessageBus";

export default {
  name: "TemplatePopup",
  props: ["showMessage", "fullScreen", "disableMapButton", "geometry"],
  computed: {},
  methods: {
    showMap() {
      minimizeAll();
    },
    copyPosition() {
      if (this.geometry && this.geometry.coordinates)
        navigator.clipboard
          .writeText(
            `http://maps.google.com/?q=${this.geometry.coordinates[1]},${this.geometry.coordinates[0]}`
          )
          .then(
            () => {
              sucessMessage("Posição do elemento foi copiado para memória");
            },
            () => {
              errorMessage(
                "Não foi possível copiar a posição do elemento, entre em contato com o suporte."
              );
            }
          );
    },
    exit() {
      closePopupBus();
    },
  },
};
</script>

<style lang="sass">
.modal-header-popup
  align-items: center !important
  height: 40px

.modal-body
  max-height: 75vh
  overflow-y: auto

.modal-body-full
  max-height: 78vh !important
  height: 93vh !important

@media(max-width: 720px)
  .modal-body
    max-height: 85vh

  .modal-body-full
    height: 82vh !important
</style>
